// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

const baseURL = window.API_URL || 'https://fp-dev3.fkcore.org/api/v2'

export const create_invoice = createAsyncThunk('appEcommerce/invoices', async params => {
  let errors = {}
  const response = await axios.post(`${baseURL}/invoices`, { ...params }).catch(error => {
    if (error && error.response && error.response.data) {
      errors = error.response.data
    }
  })
  if (response) {
    return {data: response.data, errors}
  } else {
    return {errors}
  }
})

export const getTransactions = createAsyncThunk('appEcommerce/Transactions', async params => {
  const response = await axios.get(`${baseURL}/transactions`, { params })
  return { params, data: response.data }
})

export const getTransaction = createAsyncThunk('appEcommerce/Transaction', async id => {
  const response = await axios.get(`${baseURL}/transactions/${id}`)
  return { data: response.data }
})

export const suggest = createAsyncThunk('appEcommerce/suggest', async params => {
  const name = params.name
  delete params.name
  const response = await axios.get(`${baseURL}/suggester/suggest/${name}`, { params })
  return {params, data: response.data }
})

export const getSite = createAsyncThunk('appEcommerce/getSite', async (id) => {
  const response = await axios.get(`${baseURL}/sites/${id}`)
  return {data: response.data }
})

export const getProducts = createAsyncThunk('appEcommerce/getProducts', async params => {
  const response = await axios.get('/apps/ecommerce/products', { params })
  return { data: response.data }
})

export const addToCart = createAsyncThunk('appEcommerce/addToCart', async (id, { dispatch, getState }) => {
  const response = await axios.post('/apps/ecommerce/cart', { productId: id })
  await dispatch(getProducts(getState().ecommerce.params))
  return response.data
})

export const getWishlistItems = createAsyncThunk('appEcommerce/getWishlistItems', async () => {
  const response = await axios.get('/apps/ecommerce/wishlist')
  return response.data
})

export const deleteWishlistItem = createAsyncThunk('appEcommerce/deleteWishlistItem', async (id, { dispatch }) => {
  const response = await axios.delete(`/apps/ecommerce/wishlist/${id}`)
  dispatch(getWishlistItems())
  return response.data
})

export const getCartItems = createAsyncThunk('appEcommerce/getCartItems', async () => {
  const response = await axios.get('/apps/ecommerce/cart')
  return response.data
})

export const getProduct = createAsyncThunk('appEcommerce/getProduct', async slug => {
  const response = await axios.get(`/apps/ecommerce/products/${slug}`)
  return response.data
})

export const addToWishlist = createAsyncThunk('appEcommerce/addToWishlist', async id => {
  await axios.post('/apps/ecommerce/wishlist', { productId: id })
  return id
})

export const deleteCartItem = createAsyncThunk('appEcommerce/deleteCartItem', async (id, { dispatch }) => {
  await axios.delete(`/apps/ecommerce/cart/${id}`)
  dispatch(getCartItems())
  return id
})

export const appEcommerceSlice = createSlice({
  name: 'appEcommerce',
  initialState: {
    cart: [],
    params: {},
    limit: 0,
    products: [],
    transactions: [],
    stats:0,
    transaction: [],
    wishlist: [],
    totalProducts: 0,
    productDetail: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
        .addCase(getTransactions.fulfilled, (state, action) => {
            state.transactions = action.payload.data.items
            state.stats = action.payload.data.stats
            state.params = action.payload.params
            state.limit = action.payload.data.limit
            state.offset = action.payload.data.offset
        })
        .addCase(getTransaction.fulfilled, (state, action) => {
          state.transaction = action.payload.data
        })
        .addCase(getProducts.fulfilled, (state, action) => {
        state.params = action.payload.params
        state.products = action.payload.data.products
        state.totalProducts = action.payload.data.total
      })
      .addCase(getWishlistItems.fulfilled, (state, action) => {
        state.wishlist = action.payload.products
      })
      .addCase(getCartItems.fulfilled, (state, action) => {
        state.cart = action.payload.products
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.productDetail = action.payload.product
      })
  }
})

export default appEcommerceSlice.reducer
